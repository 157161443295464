myApp.factory('wishListModel', ['$http', function($http){
    var wishListModel = {};


    wishListModel.get = function(id){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/wishlist",
            method : "GET",
            params : {},
        });
    }

    wishListModel.add = function(id){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/wishlist",
            method : "POST",
            data : {publication_id : id},
        });
    }

    wishListModel.remove = function(id){
        
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/wishlist",
            method : "DELETE",
            data : { publication_id : id },
        });
    }
    
    return wishListModel;
}]);