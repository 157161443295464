myApp.factory('productModel',['$http',function($http){

	var productModel = {};

	productModel.getFeaturedProducts = function(){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+'/publication?featured=1',
			method : "GET",
			data : {}
		});
	};

	productModel.getLatestProducts = function(){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+'/publication?latest=1',
			method : "GET",
			data : {}
		});
	};

	productModel.getRandProducts = function(){
		return $http({
			headers :{"Content-Type": "application/json"},
			url : baseUrl + '/publication/randpubs',
			method : 'GET',
			data : {}
		});
	};

	productModel.getProduct = function(id_product,data){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+'/publication/'+id_product,
			method : "GET",
			params:data,
		});
	};

	productModel.create = function(product){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+'/publication',
			method : "POST",
			data : product, /*Object*/
		});
	}

	productModel.find = function(params){
			return $http({
				headers : {'Content-Type':'application/json'},
				url : baseUrl +'/publication/search',
				method  : 'GET',
				params : params,
			});
	};

	productModel.updatePrice = function(filters){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+'/publication/update/price',
			method : "POST",
			data : filters, /*Object*/
		});
	}

	productModel.update = function(data){
		data.category_id = data.category.id;
		return $http({
			headers : {'Content-Type':'application/json'},
				url : baseUrl +'/publication/'+data.id,
				method  : 'PUT',
				data : data,
		});
	};

	productModel.deleteImage = function(id){
		var data = {image_id : id, type: 'publication'};
		return $http({
			headers : {'Content-Type':'application/json'},
				url : baseUrl +'/images',
				method  : 'DELETE',
				data : data,
		});
	};

	productModel.toggleStatus = function(pub_id,status){
		var _status = (status == true) ? 1 : 0;
		var filters = {
			id : pub_id,
			status :_status,
		};
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+"/publication/setstatus",
			method : "POST",
			data : filters
		});
	};

	productModel.getNextAdToReview = function(){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+"/publication/pending_ads/next",
			method : "GET",
			params : {},
		});
	}

	productModel.getLastAd = function(){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+"/publication/ad/last",
			method : "GET",
			params : {},
		});
	}

	return productModel;
}]);
