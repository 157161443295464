myApp.factory('slidesModel', ['$http', function ($http) {

    var slides = {};

    slides.get  = function(id){
        return $http({
            headers:{"Content-Type": "application/json"},
            url : baseUrl +'/slides/'+id,
            method : 'GET',
        });
    };

    slides.find = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/slides/find",
            method : "GET",
            params : data,
        });
    };

    slides.post = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/slides",
            method : "POST",
            data : data,
        });
    };

    slides.put = function(id,data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/slides/"+id,
            method : "PUT",
            data : data,
        });
    };

    slides.delete = function(id){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/slides/"+id,
            method : "DELETE",
            data : data,
        });
    };

    return slides;

}]);
