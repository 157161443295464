myApp.factory('departmentModel',['$http','Upload',function($http,Upload){

	var departmentModel = {};

	departmentModel.get = function(id_product){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+'/department/'+id_product,
			method : "GET",
			data:{}
		});
	};

	departmentModel.create = function(data){
		return Upload.upload({
             url: baseUrl + '/department',
             headers: {"Content-Type": "application/json"},
             method: 'POST',
             data: data
        });
	};

	departmentModel.update = function(data){
		data._method = 'PUT';
		return Upload.upload({
             url: baseUrl + '/department',
             headers: {"Content-Type": "application/json"},
             method: 'POST',
             data: data
        });
	};

	departmentModel.find = function(params){
			return $http({
				headers : {'Content-Type':'application/json'},
				url : baseUrl +'/department/search',
				method  : 'GET',
				params : params,
			});
	};

	departmentModel.deleteImage = function(id){
		var data = {image_id : id, type: 'publication'};
		return $http({
			headers : {'Content-Type':'application/json'},
				url : baseUrl +'/images',
				method  : 'DELETE',
				data : data,
		});
	};

	departmentModel.toggleStatus = function(id,active){
		var data = {id : id, active : active };
		return $http({
			headers : {'Content-Type':'application/json'},
				url : baseUrl +'/department/togglestatus/'+id,
				method  : 'PUT',
				data : data,
		});
	}

	return departmentModel;
}]);
