myApp.factory('recoverpasswordModel', ['$http', function($http){
    var recoverpasswordModel = {};


    recoverpasswordModel.reset = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/profile/resetpassword",
            method : "POST",
            data : data,
        });
    }

    
    return recoverpasswordModel;
}]);