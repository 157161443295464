myApp.factory('locationModel', ['$http', function($http){
    
    var locationModel = {};
    locationModel.getLocation  = function(location){
        return $http({
            headers:{"Content-Type": "application/json"},
			url : baseUrl+"/citiesandstate",
			method : "GET",
			params : {term : location}
        });
    }

    locationModel.getDefaultLocation = function(option){
        console.log(option);
        return $http({
            headers:{"Content-Type": "application/json"},
			url : baseUrl+"/getuserdefaultlocation",
			method : "GET",
			params : { type : option }
        });
    }

    locationModel.getCountry = function(country){
		return $http({
			headers : {'Content-Type' : 'application/json'} ,
			method : 'GET',
			url  : baseUrl + '/countries?country=' + country });
	};

	locationModel.getState  = function(country_id, state){
		return $http({
			headers : {'Content-Type' : 'application/json'} ,
			method : 'GET',
			url  : baseUrl + '/states?country_id=' + country_id +'&state='+state });
	};

	locationModel.getCity  = function(state_id, city){
		return $http({
			headers : {'Content-Type' : 'application/json'} ,
			method : 'GET',
			url  : baseUrl + '/cities?state_id=' + state_id + '&city='+city });
	};
    
	locationModel.gMapsGetCityCoordinates = function(city,state,country){
		return $http({
			url : 'https://maps.google.com/maps/api/geocode/json?address='+city+'+'+state+'&sensor=false&key='+gmaps_key,			
			method : 'GET',
			headers : {
				'Access-Control-Request-Headers': 'accept',
				'Accept' : 'application/json, text/plain, */*'
			}
		});
	};
    return locationModel;
}]);