myApp.factory('CTModel', ['$http', 'localStorageService', function ($http){ 

	var CTModel = {};

	CTModel.getToken = function(){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'POST',
			url:baseUrl + "/getcttoken",
			data : {},
		});
	};

	CTModel.getProductAvailability = function(products_stream){
		var _params = { products_stream  : products_stream  };
		
		if(ct_debug_check_stock !== undefined && typeof(ct_debug_check_stock) == 'object'){
			for (var attrname in ct_debug_check_stock) { _params[attrname] = ct_debug_check_stock[attrname]; }	
		}
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'GET',
			url:baseUrl + "/getctproductavailability",
			params : _params,
		});
	};

	CTModel.getOrdersCT = function(){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'GET',
			url:baseUrl + "/getordersct"
		});
	}

	CTModel.getPendingProducts = function(){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'GET',
			url:baseUrl + "/getpendingproducts"
		});
	}

	CTModel.getOrderCT = function(folio){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'GET',
			url:baseUrl + "/getorderct" + '/' + folio,
		});
	}

	CTModel.getProductOrderCt = function(){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'GET',
			url:baseUrl + "/getproductorderct"
		});
	};

	CTModel.generateOrderCT = function(metodo_pago){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'POST',
			url:baseUrl + "/generateorderct",
			data : {
				metodo_pago: metodo_pago
			},
		});
	};

	CTModel.confirmOrderCT = function(folio_ct){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'POST',
			url:baseUrl + "/confirmarordenct",
			data : {
				folio_ct: folio_ct
			},
		});
	};


	return CTModel;
}]);