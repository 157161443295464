myApp.factory('cartModel', ['$http', 'localStorageService', function ($http, localStorageService) {

    var cartModel = {};
    var cartList = [];

    cartModel.checkCart = function (products) {
        return $http({
            headers: {"Content-Type": "application/json"},
            url: baseUrl + '/shoppingcart/products',
            method: "POST",
            data: {
                'products': products
            }
        });
    };

    cartModel.getCartList = function () {
        cartList = localStorageService.get("cart");
        return $http({
            headers: {"Content-Type": "application/json"},
            url: baseUrl + '/shoppingcart/getProducts',
            method: "POST",
            data:{
                products: cartList
            }
        })
    };

    cartModel.setCartList = function (newCartList) {
        cartList = newCartList;
        localStorageService.set("cart", JSON.stringify(newCartList));
    };

    cartModel.cleanCart = function(){
        localStorageService.remove('cart');
    };

    cartModel.setTmpCartList = function(newcart){
        localStorageService.set('cart-tmp',JSON.stringify(newcart));
    };

    cartModel.getTmpCartList = function(){
        return (localStorageService.get('cart-tmp') != null ) ? localStorageService.get('cart-tmp') : [];
    };

    cartModel.cleanTmpCartList = function(){
        localStorageService.remove('cart-tmp');
    };

    cartModel.getProducts = async function (products) {
        oPorudcts = await $http({
            headers: {"Content-Type": "application/json"},
            url: baseUrl + '/shoppingcart/getProducts',
            method: "POST",
            data: {
                products
            }
        })
        console.log('HOLAAA')
        console.log(oPorudcts.data)
        this.cartList = oPorudcts.data.products
    };

    return cartModel;
}]);


