myApp.factory('storeModel', ['$http','Upload', function($http,Upload){
	var storeModel = {};
	var controllerBaseName = '/store'
	storeModel.find = function(filters){
		console.log(filters);
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+controllerBaseName+"/search",
			method : "GET",
			params : filters
		});
	};

	storeModel.getAdminStores = function(filters){
		console.log(filters);
		filters.check_admin = 1;
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+controllerBaseName+"/search",
			method : "GET",
			params : filters
		});
	};

	storeModel.toggleStatus = function(store_id,status){
		var _status = (status == true) ? 1 : 0;
		var filters = {
			id : store_id,
			status :_status,
		};
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+controllerBaseName+"/setstatus",
			method : "POST",
			data : filters
		});
	};

	storeModel.getFeaturedStores = function(){
		return $http({
			headers : {'Content-Type' : 'application/json'},
			url : baseUrl+ controllerBaseName+'/search',
			method : 'GET',
			params : { featured: 1 , limit : 6, latest: 1 }
		});
	};

	storeModel.getStore = function(store_id){		
				return $http({
					headers :{'Content-Type' : 'application/json'},
					url : baseUrl + controllerBaseName + "/" +store_id,
					method : 'GET',
					data : {}
				});
	};

	storeModel.create=function(storeData){

		return Upload.upload({
                 url: baseUrl + '/store',
                 headers: {"Content-Type": "application/json"},
                 method: 'POST',
                 data: storeData
                });
	};

	storeModel.update=function(storeData){
		return Upload.upload({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+controllerBaseName+"/"+storeData.id,
			method : "POST",
			data : storeData
		});
	};

	storeModel.remove=function(storeData){
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+controllerBaseName,
			method : "DELETE",
			data : { id_store : storeData.id }
		});
	};

	storeModel.getCountry = function(country){
		return $http({
			headers : {'Content-Type' : 'application/json'} ,
			method : 'GET',
			url  : baseUrl + '/countries?country=' + country });
	};

	storeModel.getState  = function(country_id, state){
		return $http({
			headers : {'Content-Type' : 'application/json'} ,
			method : 'GET',
			url  : baseUrl + '/states?country_id=' + country_id +'&state='+state });
	};

	storeModel.getCity  = function(state_id, city){
		return $http({
			headers : {'Content-Type' : 'application/json'} ,
			method : 'GET',
			url  : baseUrl + '/cities?state_id=' + state_id + '&city='+city });
	};


	storeModel.getStoreFromSession = function(id){
		return $http({
			headers : {'Content-Type':'application/json'},
			method : 'GET',
			url : baseUrl + '/store/getmine',
			params : {store_id : id},
		});
	};

	return storeModel;

}]);
