myApp.factory('ordersModel', ['$http', 'localStorageService', function ($http){

	var ordersModel = {};

	ordersModel.confirmOrder = function(order_info){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'POST',
			url:baseUrl + "/orders/proceed",
			params : order_info,
			data: order_info
		});
	};

	ordersModel.getOrderInfo = function(vb_order_id){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'GET',
			url:baseUrl + "/orders/"+vb_order_id+'/total',
		});
	};


	ordersModel.confirmPayment = function(vb_order_id,paypalresponse){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'POST',
			url:baseUrl + "/confirmpayment/"+vb_order_id,
			data : {
				paypal : paypalresponse,
			}
		});
	};

	ordersModel.saveDirectTrasferInfo = function(id,confirm_info){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'POST',
			url:baseUrl + "/direct_trasnfer/"+id,
			data : confirm_info,
		});
	};

	ordersModel.confirmDirectTrasnferInfo = function(id,confirm_info){
		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'POST',
			url:baseUrl + "/direct_transfer/confirm/"+id,
			data : confirm_info,
		});
	};

	ordersModel.getOrders = function(options){

		return $http({
			headers:{"Content-Type":"application/json"},
			method : 'GET',
			url:baseUrl + "/orders",
			params : options,

		});
	}

	ordersModel.get = function(vb_order_id){
		return $http({
			headers : {"Content-Type":"application/json" },
			method : "GET",
			url :baseUrl+"/orders/"+vb_order_id,
		});
	},

	ordersModel.getAdminOrderDetail = function(vb_order_id){
		return $http({
			headers : {"Content-Type":"application/json" },
			method : "GET",
			url :baseUrl+"/adminorders/"+vb_order_id,
		});
	}

	ordersModel.pendingorders = function(user_id){
		return $http({
			headers : {"Content-Type":"application/json" },
			method : "GET",
			url :baseUrl+"/orders/pending/"+user_id,
		});
	}

	return ordersModel;
}]);
