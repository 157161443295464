myApp.factory('propertiesModel', ['$http', function ($http) {

    var propertiesModel = {};


    propertiesModel.add = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/properties",
            method : "POST",
            data : data,
        });
    };

    propertiesModel.update = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/properties/"+data.id,
            method : "PUT",
            data : data,
        });
    };


    propertiesModel.addPropertyValue = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/propertyvalues",
            method : "POST",
            data : data,
        });
    };

    propertiesModel.updatePropertyValue = function(data){

        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/propertyvalues",
            method : "PUT",
            data : data,
        });
    };

    propertiesModel.getPropertyValues = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/propertyvalues",
            method : "GET",
            params : data,
        });
    };

    propertiesModel.get = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/properties",
            method : "GET",
            params : data,
        });
    };

    propertiesModel.getCategoryProperties = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/categoryproperties",
            method : "GET",
            params : data,
        });
    };

    propertiesModel.generateCategoryPropertyRelation = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/categoryproperties",
            method : "POST",
            data : data,
        });
    };

    return propertiesModel;

}]);
