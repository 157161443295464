myApp.factory('checkoutModel', ['$http', 'localStorageService', function ($http, localStorageService) {
        var checkoutModel = {};
        var checkout = {};
        var shippingMethod = {};

        checkoutModel.getCheckoutInfo = function () {

            return  JSON.parse(sessionStorage.getItem('checkout-1') );

        };

        checkoutModel.setCheckoutInfo = function (newCheckout) {

            //localStorageService.set("checkout-1", JSON.stringify(newCheckout));
            sessionStorage.setItem("checkout-1", JSON.stringify(newCheckout));

        };

        checkoutModel.setCheckoutAddress = function(data){
            //localStorageService.set("checkout-address", JSON.stringify(data));
            sessionStorage.setItem('checkout-address',JSON.stringify(data));
        }

        checkoutModel.getCheckoutAddress = function(data){
            return JSON.parse(sessionStorage.getItem("checkout-address") );
        }

        checkoutModel.deleteCheckoutAddress = function(){
            sessionStorage.removeItem("checkout-address");
        }

        checkoutModel.setShippingMethod = function (newData) {
            //shippingMethod = newData;
            //localStorageService.set("checkout-2", JSON.stringify(shippingMethod));
            sessionStorage.setItem('checkout-2',JSON.stringify(newData));
        };

        checkoutModel.getShippingMethod = function () {
            /*if (localStorageService.get("checkout-2") !== undefined) {
                shippingMethod = localStorageService.get("checkout-2");
            }*/
            return JSON.parse(sessionStorage.getItem('checkout-2') );
        };

        checkoutModel.deleteShippingMethod = function(){
            sessionStorage.removeItem('checkout-2');
        }

        checkoutModel.setPayMethod  = function(data){
            //localStorageService.set("checkout-paymethod", data);
            sessionStorage.setItem('checkout-paymethod',data);
        };

        checkoutModel.getPayMethod = function(){
            return sessionStorage.getItem('checkout-paymethod');
            //return localStorageService.get("checkout-paymethod");
        };

        checkoutModel.deletePayMethod = function(){
            sessionStorage.removeItem('checkout-paymethod');
        };


        /**
            SERVICES
        */

        checkoutModel.getStoresLocation  = function(stores_stream){
            return $http({
                headers:{"Content-Type":"application/json"},
                method : 'POST',
                url:baseUrl + "/getstoreslocation",
                params : {
                    stores_stream : stores_stream,
                }
            });
        }

        return checkoutModel;
    }]);
