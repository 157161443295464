myApp.factory("userModel",
                [   "$http","localStorageService",
                    function(
                            $http,localStorageService
                            ){
	var userModel = {};


	userModel.registerUser=function(formData){
		return $http({
			headers:{"Content-Type":"application/json"},
			url:baseUrl + "/register",
			method:"POST",
			data:{
				name:formData.name,
				email:formData.email,
				password:formData.password,
				password_confirmation:formData.password_confirmation
			}
		});
	};

	userModel.getAdminUsers = function(filters){
		return $http({
			headers:{"Content-Type":"application/json"},
			url:baseUrl + "/user/search",
			method:"GET",
			params :filters,
		});
	};

	userModel.toggleStatus = function(user_id,activated){
		var _activated = (activated == true) ? 1 : 0;
		var filters = {
			id : user_id,
			activated :_activated,
		};
		return $http({
			headers:{"Content-Type": "application/json"},
			url : baseUrl+"/user/setstatus",
			method : "POST",
			data : filters
		});
	}

	userModel.saveUser = function(name,id,type){
		localStorageService.set('username',name);
		localStorageService.set('user_id',id);
		localStorageService.set('user_type',type);
	};

	userModel.getSavedUser = function(){
		return localStorageService.get('username');
	};
	userModel.getSavedUserId = function(){
		return localStorageService.get('user_id');
	};
	userModel.getSavedUserType = function(){
		return localStorageService.get('user_type');
	};
	userModel.deleteSavedUser = function(){
		localStorageService.removeitem('username');
	}
	userModel.deleteSavedUserId = function(){
		localStorageService.removeitem('user_id');
	}

	userModel.getUser = function(){
		return $http({
			headers:{"Content-Type":"application/json"},
			url : baseUrl +'/user',
			method: 'GET',
		});
	};

	userModel.get = function(user_id){
		return $http({
			headers:{"Content-Type":"application/json"},
			url : baseUrl +'/user/'+user_id,
			method: 'GET',
		});
	};

	userModel.userHasStore = function(){
		return $http({
			headers :{'Content-Type' : 'application/json'},
			url : baseUrl+'/userhasstore',
			method  : 'GET',
			data :{}
		});
	};
        
    userModel.recoveryPassword = function(userData){
		return $http({
			headers :{'Content-Type' : 'application/json'},
			url : baseUrl+'/profile/recoverypassword',
			method  : 'POST',
			data :{
				email:userData.email,
			}
		});
	};

	userModel.update = function(data){
		return $http({
			headers : {'Content-Type': 'application/json'},
			url : baseUrl + '/user',
			method : 'PUT',
			data : data,
		});
	};

	userModel.confirmEmail = function(code){				
		return $http({
			headers : {'Content-Type': 'application/json'},
			url : baseUrl + '/user/confirmemail/'+code,
			method : 'GET',
			params : {},
		});
	};

	userModel.resendUserConfirmationEmail = function(id){
		return $http({
			headers : {'Content-Type': 'application/json'},
			url : baseUrl + '/user/resendconfirmationemail',
			method : 'POST',
			data : {user_id : id},
		});
	};
        
	return userModel;
}]);
