myApp.factory('slidesCarousellModel', ['$http', function ($http) {

    var slidesCarousellModel = {};


    slidesCarousellModel.get = function(id){
        return $http({
            headers:{"Content-Type": "application/json"},
            url : baseUrl +'/slidescarousell/'+id,
            method : 'GET',
        });
    };

    slidesCarousellModel.getcarousell = function(path){
        var data = { path : path  };
        return $http({
            headers:{"Content-Type": "application/json"},
            url : baseUrl +'/slidescarousell/getfromurl',
            method : 'GET',
            params : data,
        });
    };

    slidesCarousellModel.find  = function(data){
        return $http({
            headers:{"Content-Type": "application/json"},
            url : baseUrl +'/slidescarousell/find',
            method : 'GET',
            params : data,
        });
    };

    slidesCarousellModel.post = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/slidescarousell",
            method : "POST",
            data : data,
        });
    };

    slidesCarousellModel.put = function(id,data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/slidescarousell/"+id,
            method : "PUT",
            data : data,
        });
    };

    slidesCarousellModel.delete = function(id){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/slidescarousell/"+id,
            method : "DELETE",
            data : data,
        });
    };

    return slidesCarousellModel;

}]);
