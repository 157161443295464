myApp.factory('categoriesModel', ['$http', 'localStorageService', function ($http, localStorageService) {
    
    var categoriesModel = {};
    categoriesModel.getCategories  = function(data){
        return $http({
            headers:{"Content-Type": "application/json"},
            url : baseUrl +'/categories',
            method : 'GET',
            params : data,
        });
    };

    categoriesModel.getCategoriesFromFilters = function(params){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "",
            method : "GET",
            params : params,
        });
    };

    categoriesModel.getCartInvalidCategories = function(){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/invalidcartcategories",
            method : "GET",            
        });
    }

    categoriesModel.getCartValidCategories = function(){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/invalidcartcategories",
            method : "GET",
            params : {valid_categories : 'valid'},
        });
    }

    categoriesModel.addCartInvalidCategory  = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/invalidcartcategories",
            method : "POST",
            data : data,
        });
    }

    categoriesModel.removeCartInvalidCategory  = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/invalidcartcategories",
            method : "DELETE",
            data : data,
        });
    }

    categoriesModel.getCategorySubcategoreis = function(id){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/getcategorysubcategories?category_id="+id,
            method : "GET",
            data : data,
        });    
    }

    categoriesModel.create = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/categories",
            method : "POST",
            data : data,
        });    
    }

    categoriesModel.update = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/categories",
            method : "PUT",
            data : data,
        });   
    } 

    return categoriesModel;

}]);