myApp.factory('shippingAddressModel', ['$http', 'localStorageService', function ($http, localStorageService) {

    var shippingAddressModel = {};

    shippingAddressModel.get = function (id) {
        return $http({
            headers: {"Content-Type": "application/json"},
            url: baseUrl + '/shippingaddress',
            method: "GET",
        });
    };

    shippingAddressModel.save = function (data) {

        return $http({
            headers: {"Content-Type": "application/json"},
            url: baseUrl + '/shippingaddress',
            method: "POST",
            data: data,
        });
    };

    shippingAddressModel.put = function (data) {
        return $http({
            headers: {"Content-Type": "application/json"},
            url: baseUrl + '/shippingaddress/'+data.id,
            method: "PUT",
            data: data,
        });;
    };

    shippingAddressModel.delete = function (id) {
        return $http({
            headers: {"Content-Type": "application/json"},
            url: baseUrl + '/shippingaddress/'+id,
            method: "DELETE",

        });
    };

    return shippingAddressModel;
}]);
