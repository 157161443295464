myApp.factory('brandsModel', ['$http', function ($http) {

    var brandsModel = {};

    brandsModel.get  = function(data){
        return $http({
            headers:{"Content-Type": "application/json"},
            url : baseUrl +'/brands',
            method : 'GET',
            params : data,
        });
    };

    brandsModel.post = function(data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/brands",
            method : "POST",
            data : data,
        });
    };

    brandsModel.put = function(id,data){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/brands/"+id,
            method : "PUT",
            data : data,
        });
    };

    brandsModel.delete = function(id){
        return $http({
            headers :{"Content-Type": 'application/json'},
            url : baseUrl + "/brands/"+id,
            method : "DELETE",
            data : data,
        });
    };

    return brandsModel;

}]);
